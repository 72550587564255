import { defaultLanguage } from '../../prismic-config'
import { default as config } from '../../config.json'
import { filterConfig } from './funcs'

/* The Link Resolver
* This function will be used to generate links to Prismic documents
As your project grows, you should update this function according to your routes */

const locales = 'locales' in config ? config['locales'] : config;
const booksUid = filterConfig(locales, 'booksUID');
const projectUid = filterConfig(locales, 'projectUID');
const commitmentUid = filterConfig(locales, 'commitmentUID');
const langSlug = Object.entries(locales)
  .reduce(
    (prev, [key, val]) => {
      prev[key] = val['isDefault'] ? '' : key;
      return prev;
    }, {}
  )

const linkResolver = (doc) => {
  const properties = doc ? doc['_meta'] || doc : null;

  if( !properties ) { return '#no-target-for-link'}

  if (properties.type === 'homepage') {
    return properties.lang === defaultLanguage ? '/' : `/${properties.lang}`
  }

  if (properties.type === 'archive') {
    return properties.lang === defaultLanguage
      ? `/${properties.uid}`
      : `/${properties.lang}/${properties.uid}`
  }


  if (properties.type === 'page') {
    return properties.lang === defaultLanguage
      ? `${properties.uid}`
      : `/${properties.lang}/${properties.uid}`
  }

  if ( properties.type === 'post' || properties.type === 'tag' ) {
    return properties.lang === defaultLanguage
      ? `${langSlug[properties.lang]}/blog/${properties.uid}`
      : `/${langSlug[properties.lang]}/blog/${properties.uid}`
  }

  if (properties.type === 'book') {
    return properties.lang === defaultLanguage
      ? `${booksUid[properties.lang]}/${properties.uid}`
      : `/${langSlug[properties.lang]}/${booksUid[properties.lang]}/${properties.uid}`
  }

  if (properties.type === 'project') {
    return properties.lang === defaultLanguage
      ? `${projectUid[properties.lang]}/${properties.uid}`
      : `/${langSlug[properties.lang]}/${projectUid[properties.lang]}/${properties.uid}`
  }

  if (properties.type === 'commitment') {
    return properties.lang === defaultLanguage
      ? `${commitmentUid[properties.lang]}/${properties.uid}`
      : `/${langSlug[properties.lang]}/${commitmentUid[properties.lang]}/${properties.uid}`
  }

  if (properties.type === 'tag') {
    return properties.lang === defaultLanguage
      ? `${commitmentUid[properties.lang]}/${properties.uid}`
      : `/${langSlug[properties.lang]}/${commitmentUid[properties.lang]}/${properties.uid}`
  }

  return '/'
};

export default linkResolver
