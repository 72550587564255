/**
 * Omit other fields except byKey from new object as a config
 * @param { Object } config Configuration data from json
 * @param { string } byKey Key of property from config to read
 *
 * @returns New config object only with selected properties
 * */
const filterConfig = (config, byKey) => {
  return Object.entries(config)
    .reduce(
      (prev, [key, value]) => {
        prev[key] = value[byKey]
        return prev
      }, {}
    )
}

/**
 * Builds url from configuration json file
 *
 * @param { Object } config Configuration data from json
 * @param { string } byKey Key of property from config to read
 * @param { Array<string> } except Array of exceptions to exclude from result
 *
 * @return { Object }
 *
 * */
const pageUrlBuilder = (config, byKey, except = []) => {
  if(!byKey || byKey.length === 0)
    return {};
  
  let defaultProp = Object.values(config.locales).filter((v) => !!v['isDefault']);
  
  if(defaultProp.length > 0)
    defaultProp = defaultProp[0];
  else
    defaultProp = {};
  
  return Object.entries(config.locales)
    .reduce(
      (prev, [langkey, value]) => {
        const isoKey = value['isoCode'];
        if(except.includes(langkey) || !(byKey in value))
        {
          prev[isoKey] = `/${defaultProp[byKey]}/`;
          return prev;
        }
        prev[isoKey] = !!value['isDefault']
          ? `/${value[byKey]}/`
          : `/${langkey}/${value[byKey]}/`;
        return prev;
      },
      {}
    );
}

/**
 * Extracts data got from graphql query
 *
 * @param { Object } config Configuration data
 * @param { Object } node Node from fetch data
 * @param {boolean} hasMeta Does response have _meta field
 * */
const extractNodeMetadata = (config, node, hasMeta = false) => {
  const data = hasMeta ? node['_meta'] : node;
  const lang = data['lang'];
  const locales = 'locales' in config ? config['locales'] : config;
  
  return {
    lang,
    uid: data['uid'],
    langIso: locales[lang]['isoCode'],
    isDefault: locales[lang]['isDefault'] || false,
    commitmentUid: locales[lang]['commitmentUID'],
    projectUid: locales[lang]['projectUID'],
  }
}

module.exports = {
  filterConfig,
  pageUrlBuilder,
  extractNodeMetadata
}
