const { locales, repo_name } = require('./config.json');

const prismicRepo = repo_name;
const defaultLanguage = Object.entries(locales).filter(
  ([, v]) => v['isDefault'] === true
).reduce(
  (prev, [k]) => k, ''
);
const langs = Object.keys(locales);

module.exports = {
  prismicRepo,
  defaultLanguage,
  langs
}
